exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-conditions-vente-js": () => import("./../../../src/pages/conditions-vente.js" /* webpackChunkName: "component---src-pages-conditions-vente-js" */),
  "component---src-pages-contactez-nous-js": () => import("./../../../src/pages/contactez-nous.js" /* webpackChunkName: "component---src-pages-contactez-nous-js" */),
  "component---src-pages-datacloud-linux-js": () => import("./../../../src/pages/datacloud-linux.js" /* webpackChunkName: "component---src-pages-datacloud-linux-js" */),
  "component---src-pages-datacloud-windows-js": () => import("./../../../src/pages/datacloud-windows.js" /* webpackChunkName: "component---src-pages-datacloud-windows-js" */),
  "component---src-pages-datafiles-js": () => import("./../../../src/pages/datafiles.js" /* webpackChunkName: "component---src-pages-datafiles-js" */),
  "component---src-pages-datagames-js": () => import("./../../../src/pages/datagames.js" /* webpackChunkName: "component---src-pages-datagames-js" */),
  "component---src-pages-datagames-minecraft-js": () => import("./../../../src/pages/datagames-minecraft.js" /* webpackChunkName: "component---src-pages-datagames-minecraft-js" */),
  "component---src-pages-datagames-rust-js": () => import("./../../../src/pages/datagames-rust.js" /* webpackChunkName: "component---src-pages-datagames-rust-js" */),
  "component---src-pages-datamail-js": () => import("./../../../src/pages/datamail.js" /* webpackChunkName: "component---src-pages-datamail-js" */),
  "component---src-pages-datarent-js": () => import("./../../../src/pages/datarent.js" /* webpackChunkName: "component---src-pages-datarent-js" */),
  "component---src-pages-datastore-js": () => import("./../../../src/pages/datastore.js" /* webpackChunkName: "component---src-pages-datastore-js" */),
  "component---src-pages-dataweb-js": () => import("./../../../src/pages/dataweb.js" /* webpackChunkName: "component---src-pages-dataweb-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-protection-donnees-personnelles-js": () => import("./../../../src/pages/protection-donnees-personnelles.js" /* webpackChunkName: "component---src-pages-protection-donnees-personnelles-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

